import React, { useEffect } from "react"
import styled from "styled-components"

import { respTextFont } from "../../utils/sharedStyles"
import Button from "../shared/Button"

const TutorialAgreementWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 180px;
  width: 100%;
  padding: 20px;
  bottom: 0;
  background: rgba(9, 8, 8, 0.9);
  border-top: ${({ theme }) => `2px solid ${theme.colors.interactiveBorder}`};
  z-index: 4;
`

const Text = styled.div`
  ${respTextFont}
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledButton = styled(Button)`
  margin: 0.3rem;
`

const TutorialAgreement = ({ handleYes, handleNo }) => {
  useEffect(() => {
    window.addEventListener("click", handleNo)
    return () => {
      window.removeEventListener("click", handleNo)
    }
  }, [])

  return (
    <TutorialAgreementWrapper data-cy="tutorial-popup-home-page">
      <Text>
        Do you want to go through a short tutorial showing how the application
        works?
      </Text>
      <ButtonWrapper>
        <StyledButton onClick={handleYes}>Yes</StyledButton>
        <StyledButton onClick={handleNo} secondary>
          No
        </StyledButton>
      </ButtonWrapper>
    </TutorialAgreementWrapper>
  )
}

export default TutorialAgreement
