import React, { useState, useEffect } from "react"

import { useAppState } from "../../../../../context/context"
import SelectOptionsContainer from "../../../../shared/Select/SelectOptionsContainer"
import SelectOption from "../../../../shared/Select/SelectOption"
import SelectFieldContainer from "../../../../shared/Select/SelectFieldContainer"

const Select = ({ inputData, handleSelect }) => {
  const AppState = useAppState()
  const { selectedDocument } = AppState
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>(selectedDocument.textShort)

  const handleCloseOptions = () => {
    setShowOptions(false)
  }

  useEffect(() => {
    if (showOptions === true) {
      window.addEventListener("click", handleCloseOptions)
      return () => {
        window.removeEventListener("click", handleCloseOptions)
      }
    }
  }, [showOptions])

  return (
    <>
      <SelectFieldContainer data-cy="select-document-home-page" onClick={() => setShowOptions(true)}>
        {selected}
      </SelectFieldContainer>
      {inputData && showOptions && (
        <SelectOptionsContainer>
          {inputData.map(data => (
            <SelectOption
              data-cy="choose-document-home-page"
              selected={data.textShort === selected}
              key={data.id}
              onClick={() => {
                setSelected(data.textShort)
                handleSelect(data)
                setShowOptions(false)
              }}
            >
              {data.textShort}
            </SelectOption>
          ))}
        </SelectOptionsContainer>
      )}
    </>
  )
}

export default Select
