import React from "react"

const CloudDownload = props => {
  return (
    <svg {...props} viewBox="0 0 26 17.333">
      <path
        d="M18.417 9.75L13 15.167 7.583 9.75h3.25V5.417h4.333V9.75m5.8-3.218A8.122 8.122 0 005.8 4.366a6.5 6.5 0 00.7 12.968h14.083a5.407 5.407 0 00.379-10.8z"
        fill="#fff"
      />
    </svg>
  )
}

export default CloudDownload
