import React from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"

import { useAppState, useAppDispatch } from "../../../../../../context/context"
import InteractiveContainer from "../../../../../shared/InteractiveContainer"
import SimpleContainer from "../../../../../shared/SimpleContainer"
import DeleteIcon2 from "../../../../../../utils/assets/DeleteIcon2"
import H5 from "../../../../../shared/H5"
import { respTextFont } from "../../../../../../utils/sharedStyles"

const StyledInteractiveContainer = styled(InteractiveContainer)`
  display: flex;
  flex-direction: row;
  padding: 0;
  overflow: auto;
  height: 400px;
  justify-content: space-around;
`

const AspectsWrapper = styled.div`
  width: 45%;
`

const StyledSimpleContainer = styled(SimpleContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0;
  cursor: default;
  padding: 4px 8px;

  ${({ theme }) => theme.media.tablet} {
    padding: 3px 6px;
  }

  ${({ theme }) => theme.media.phone} {
    padding: 2px 4px;
  }
`

const StyledSelectedSpan = styled.span`
  ${respTextFont}
  color: blue;
  cursor: pointer;
`

const StyledUnselectedSpan = styled.span`
  cursor: pointer;
`

const StyledDeleteIcon2 = styled(DeleteIcon2)`
  height: 0.8rem;
  cursor: pointer;
`

const Aspects = ({
  aspectsUpdate,
  handleAddToSelected,
  handleRemoveFromSelected,
}) => {
  const AppState = useAppState()
  const dispatch = useAppDispatch()
  const [cookies, setCookie] = useCookies(["aspectsBase"])

  const handleRemoveAspect = aspect => {
    const filteredAspects = AppState.aspectsBase.filter(
      oldAspect => oldAspect !== aspect
    )
    dispatch({ type: "REMOVE_ASPECT_FROM_BASE", payload: filteredAspects })
    setCookie("aspectsBase", filteredAspects)
  }

  const selectedAspects = AppState.aspectsBase
    .filter(aspect => aspectsUpdate.includes(aspect))
    .map((aspect, index) => (
      <StyledSimpleContainer key={index}>
        <StyledSelectedSpan onClick={() => handleRemoveFromSelected(aspect)}>
          {aspect}
        </StyledSelectedSpan>
      </StyledSimpleContainer>
    ))

  const unselectedAspects = AppState.aspectsBase
    .filter(aspect => !aspectsUpdate.includes(aspect))
    .map((aspect, index) => (
      <StyledSimpleContainer key={index}>
        <StyledUnselectedSpan onClick={() => handleAddToSelected(aspect)}>
          {aspect}
        </StyledUnselectedSpan>
        <StyledDeleteIcon2 
          data-cy={`remove-${aspect}-manager`}
          onClick={() => handleRemoveAspect(aspect)}
        ></StyledDeleteIcon2>
      </StyledSimpleContainer>
    ))

  return (
    <>
      <H5>Aspects</H5>
      <StyledInteractiveContainer data-cy="aspects-base-manager">
        <AspectsWrapper data-cy="used-aspects-base-manager">{selectedAspects}</AspectsWrapper>
        <AspectsWrapper data-cy="unused-aspects-base-manager">{unselectedAspects}</AspectsWrapper>
      </StyledInteractiveContainer>
    </>
  )
}

export default Aspects
