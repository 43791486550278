import React from "react"
import styled from "styled-components"

import H5 from "../../../../../shared/H5"
import ExpandIcon from "../../../../../../utils/assets/ExpandIcon"
import SimpleContainer from "../../../../../shared/SimpleContainer"

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
`

const MoveContainer = styled(SimpleContainer)<{ disabled: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: ${props => (props.disabled ? "0.5" : "0.75")};
`

const BackIcon = styled(ExpandIcon)`
  transform: rotate(90deg);
`

const ForwardIcon = styled(ExpandIcon)`
  transform: rotate(-90deg);
`

const SelectSpan = ({
  handleSpanChangeBack,
  handleSpanChangeForw,
  examples,
  spansIndex,
}) => {
  return (
    <>
      <H5>Select span</H5>
      <ButtonsContainer>
        <MoveContainer
          data-cy="back-button-inspect-mode"
          disabled={examples[spansIndex] === examples[0]}
          onClick={handleSpanChangeBack}
        >
          <BackIcon />
          Back
        </MoveContainer>
        <MoveContainer
          data-cy="forward-button-inspect-mode"
          disabled={examples[spansIndex] === examples[examples.length - 1]}
          onClick={handleSpanChangeForw}
        >
          Forward
          <ForwardIcon />
        </MoveContainer>
      </ButtonsContainer>
    </>
  )
}

export default SelectSpan
