import React from "react"

import { useAppState, useAppDispatch } from "../../../../context/context"
import H5 from "../../../shared/H5"
import Select from "./SelectDocumentComponents/Select"

const SelectDocument = () => {
  const AppState = useAppState()
  const dispatch = useAppDispatch()

  const handleSelect = (data) => {
    dispatch({type: "SELECT_DOCUMENT", payload: data })
  }

  return (
    <>
      <H5>Select document</H5>
      <Select inputData={AppState.documents} handleSelect={handleSelect} />
    </>
  )
}

export default SelectDocument
