import React from "react"
import styled from "styled-components"

import { respTextFont } from "../../utils/sharedStyles"

const BubbleContainer = styled.div`
  ${respTextFont}
  position: relative;
  line-height: 24px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  color: black;

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 14px solid #fff;
    border-right: 8px solid transparent;
    border-top: 10px solid #fff;
    border-bottom: 15px solid transparent;
    left: 26px;
    bottom: -20px;
  }
`

const Bubble = props => {
  return <BubbleContainer data-cy="speech-bubble-shared" {...props}>{props.children}</BubbleContainer>
}

export default Bubble
