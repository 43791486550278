import React, { useState } from "react"
import styled, { css } from "styled-components"

import { useAppState, useAppDispatch } from "../../../../context/context"
import H5 from "../../../shared/H5"
import DeleteIcon from "../../../../utils/assets/DeleteIcon"
import SimpleContainer from "../../../shared/SimpleContainer"
import { respTextFont } from "../../../../utils/sharedStyles"
import EditAspectsModal from "./EditAspectsModal/EditAspectsModal"
import AddAspectShortcut from "./AspectsUsedComponents/AddAspectShortcut"

const ChipContainer = styled(SimpleContainer)`
  padding: 0 0 0 6px;
  cursor: default;
`

const sharedStyles = css`
  ${respTextFont}

  color: ${({ theme }) => theme.colors.secondary};
`

const ShortcutWrapper = styled.div`
  ${sharedStyles};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  flex-grow: 1;
`


const ButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 7px;
`

const StyledCustomButton = styled.div`
  ${sharedStyles}
  cursor: pointer;
`

const ChipsWrapper = styled.ul`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Chip = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 6px;
  padding: 0 0.4rem;
  border: 1px solid ${({ theme }) => theme.colors.background};
  border-radius: 0.7rem;
`

const AspectContainer = styled.div`
  padding-right: 0.6rem;
`

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  height: 0.9rem;
  width: 0.9rem;

  ${({ theme }) => theme.media.phone} {
    height: 0.8rem;
    width: 0.8rem;
  }
`

const AspectsUsed = () => {
  const AppState = useAppState()
  const dispatch = useAppDispatch()
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const handleModalVisibility = () => {
    setModalVisible(!modalVisible)
  }

  const handleRemoveAspect = aspect => {
    const filteredAspects = AppState.selectedDocument.aspects.filter(
      oldAspect => oldAspect !== aspect
    )
    dispatch({ type: "SET_ASPECT_FROM_DOCUMENT", payload: filteredAspects })
  }

  return (
    <>
      <H5>Aspects Used</H5>
      <ChipContainer data-cy="aspects-home-page">
        <ChipsWrapper>
          {AppState.selectedDocument.aspects.map(aspect => (
            <Chip key={aspect}>
              <AspectContainer>{aspect}</AspectContainer>
              <StyledDeleteIcon data-cy={`remove-${aspect}-home-page`} onClick={() => handleRemoveAspect(aspect)} />
            </Chip>
          ))}
          <ShortcutWrapper>
            <AddAspectShortcut />
          </ShortcutWrapper>
          <ButtonContainer>
            <StyledCustomButton data-cy="edit-button-home-page" onClick={handleModalVisibility}>
              Edit
            </StyledCustomButton>
          </ButtonContainer>
        </ChipsWrapper>
      </ChipContainer>
      {modalVisible && (
        <EditAspectsModal
          handleRemoveAspect={handleRemoveAspect}
          handleModalVisibility={handleModalVisibility}
        />
      )}
    </>
  )
}

export default AspectsUsed
