import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useAppState } from "../../../context/context"
import { buttonIconsStyle } from "../../../utils/sharedStyles"
import InteractiveContainer from "../../shared/InteractiveContainer"
import InspectModeModal from "./SecPanelComponents/InspectModeModal/InspectModeModal"
import Button from "../../shared/Button"
import Keys from "./SecPanelComponents/Keys"
import CloudDownload from "../../../utils/assets/CloudDownload"
import TextContainer from "./SecPanelComponents/TextContainer"
import AspectsContainer from "./SecPanelComponents/SecPanelComponents/AspectsContainer"
import Tutorial from "../../tutorial/Tutorial"
import Loader from "../../shared/Loader";

const KeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const OutputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
`

const AspectsWrapper = styled(InteractiveContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.interactiveBorder}`};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`

const TextOutput = styled(InteractiveContainer)`
  position: relative;
  flex-grow: 1;
  border-top: ${({ theme }) => `1px solid ${theme.colors.interactiveBorder}`};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-right: 2rem;

  ${({ theme }) => theme.media.tablet} {
    line-height: 1.2rem;
  }

  ${({ theme }) => theme.media.phone} {
    line-height: 1rem;
  }
`

const LoadingContainer = styled(InteractiveContainer)`
  flex-grow: 1;
`

const ButtonContainer = styled.div`
  opacity: 0.2;
`

const StyledIcon = styled(CloudDownload)`
  ${buttonIconsStyle}
`

const SecPanel = ({ tutorialStep, handleChangeTutorialHint, tutorialSequence }) => {
  const AppState = useAppState()
  const {
    dataLoading,
    responseError,
    responseErrorMsg,
    outputData,
    indexTutorialVisibility,
  } = AppState
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [selectedAspects, setSelectedAspects] = useState([AppState.outputData.subTasks[0]])
  const [selectedAspectsIndex, setSelectedAspectsIndex] = useState<number>(0)
  const [selectedSpansIndex, setSelectedSpansIndex] = useState(undefined)

  const handleModalVisibility = () => {
    setModalVisible(!modalVisible)
  }

  //Chip select handler
  const handleSelectAspects = (aspect, index) => {
    if (selectedAspects.includes(aspect)) {
      const newSelectedAspects = selectedAspects.filter(a => a !== aspect)
      setSelectedAspects(newSelectedAspects)
      if (newSelectedAspects.length) {
        const key = outputData.subTasks.indexOf(newSelectedAspects[0])
        setSelectedAspectsIndex(key)
      } else {
        setSelectedAspectsIndex(0)
      }
    } else {
      setSelectedAspects([...selectedAspects, aspect])
      setSelectedAspectsIndex(index)
    }
  }

  //Load first aspect after data load/fetch
  useEffect(() => {
    setSelectedAspects([AppState.outputData.subTasks[0]])
  }, [AppState.outputData])

  return (
    <>
      <KeyContainer data-cy="color-legend-home-page">
        <Keys />
      </KeyContainer>
      <OutputContainer data-cy="result-panel-home-page">
        {dataLoading && <Loader/>}
        {responseError && <LoadingContainer data-cy="error-result-home-page">
        {responseErrorMsg}</LoadingContainer>}
        {!dataLoading && !responseError && outputData.subTasks && (
          <>
            <AspectsWrapper>
              <Tutorial
                handleChangeTutorialHint={handleChangeTutorialHint}
                tutorialStep={tutorialStep}
                tutorialSequence={tutorialSequence}
                tutorialTip={tutorialStep === 4 && indexTutorialVisibility}
              >
                <AspectsContainer
                  selectedAspects={selectedAspects}
                  handleSelectAspects={handleSelectAspects}
                  setSelectedAspectsIndex={setSelectedAspectsIndex}
                />
              </Tutorial>
            </AspectsWrapper>
            <TextOutput>
              <Tutorial
                handleChangeTutorialHint={handleChangeTutorialHint}
                tutorialStep={tutorialStep}
                tutorialSequence={tutorialSequence}
                tutorialTip={tutorialStep === 5 && indexTutorialVisibility}
              >
                <TextContainer
                  selectedAspects={selectedAspects}
                  handleModalVisibility={handleModalVisibility}
                  setSelectedSpansIndex={setSelectedSpansIndex}
                />
              </Tutorial>
            </TextOutput>
          </>
        )}
      </OutputContainer>
      {modalVisible && (
        <InspectModeModal
          selectedAspectsIndex={selectedAspectsIndex}
          selectedSpansIndex={selectedSpansIndex}
          handleModalVisibility={handleModalVisibility}
        />
      )}
    </>
  )
}

export default SecPanel
