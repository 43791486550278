import React from "react"
import styled, { css } from "styled-components"
import { respTextFont, backgroundStyles } from "../../../../utils/sharedStyles"

const sharedStyles = css`
  ${backgroundStyles}
  ${respTextFont}
  
  padding: 0px 30px;
  margin: 0 0 5px 10px;
`

const Pos = styled.span`
  ${sharedStyles};
  background: ${({ theme }) => theme.colors.positive};
`

const Neu = styled.span`
  ${sharedStyles};
  background: ${({ theme }) => theme.colors.neutral};
`

const Neg = styled.span`
  ${sharedStyles};
  background: ${({ theme }) => theme.colors.negative};
`

const Keys = () => {
  return (
    <>
      <Pos>POS</Pos>
      <Neu>NEU</Neu>
      <Neg>NEG</Neg>
    </>
  )
}

export default Keys
