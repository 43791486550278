import React from "react"
import styled from "styled-components"

import H5 from "../../../../../shared/H5"
import InteractiveContainer from "../../../../../shared/InteractiveContainer"
import { respTextFont, backgroundStyles } from "../../../../../../utils/sharedStyles"

const backgroundColor = props => {
  if (props.sentiment === "positive") {
    return "rgba(63, 224, 224, 1)"
  } else if (props.sentiment === "negative") {
    return "rgba(255, 74, 60, 1)"
  } else {
    return "rgba(213, 213, 213, 1)"
  }
}

const StyledInteractiveContainer = styled(InteractiveContainer)`
  width: 100%;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  min-height: 0;
`

const Text = styled.p<{ sentiment: string }>`
  ${respTextFont}
  ${backgroundStyles}
  height: 100%;
  background: ${props => backgroundColor(props)};
`

const SpanSentiment = ({ selectedSpan }) => {
  return (
    <div>
      <H5>Sentiment</H5>
      <StyledInteractiveContainer data-cy="sentiment-inspect-mode">
        <Text sentiment={selectedSpan.sentiment}>{selectedSpan.sentiment}</Text>
      </StyledInteractiveContainer>
    </div>
  )
}

export default SpanSentiment
