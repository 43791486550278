import React, { useState } from "react"

import { useAppState } from "../../../../../../context/context"
import H5 from "../../../../../shared/H5"
import SelectFieldContainer from "../../../../../shared/Select/SelectFieldContainer"
import SelectOptionsContainer from "../../../../../shared/Select/SelectOptionsContainer"
import SelectOption from "../../../../../shared/Select/SelectOption"

const SelectAspect = ({ aspectIndex, handleAspectSelect }) => {
  const AppState = useAppState()
  const { outputData } = AppState
  const [showOptions, setShowOptions] = useState<boolean>(false)

  return (
    <>
      <H5>Selected aspect</H5>
      <SelectFieldContainer
        data-cy="selected-aspect-inspect-mode"
        onClick={() => {
          setShowOptions(!showOptions)
        }}
      >
        {outputData.subTasks[aspectIndex].aspect}
      </SelectFieldContainer>
      {outputData && showOptions && (
        <SelectOptionsContainer>
          {outputData.subTasks.map((aspect, index) => (
            <SelectOption
              key={index}
              onClick={() => {
                handleAspectSelect(index)
                setShowOptions(false)
              }}
            >
              {aspect.aspect}
            </SelectOption>
          ))}
        </SelectOptionsContainer>
      )}
    </>
  )
}

export default SelectAspect
