import React from "react"
import styled from "styled-components"

import { respH3Font } from "../../../../../../utils/sharedStyles"

const StyledH3 = styled.h3`
  ${respH3Font}
`

const ModalHeader = () => {
  return (
    <>
      <StyledH3>What aspects do you want to use?</StyledH3>
    </>
  )
}

export default ModalHeader
