import React from "react"
import styled, { css } from "styled-components"

import Bubble from "../shared/Bubble"
import Caption from "./TutorialComponents/Caption"

const StyledBubble = styled(Bubble)`
  position: absolute;
  top: -80px;
  z-index: 5;
`

const ChildrenWrapper = styled.div`
  position: inherit;
  z-index: 4;
  width: 100%;
  height: 100%;
`

const sharedStyled = css`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Background = styled.div`
  ${sharedStyled}
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`

const NextHandler = styled.div`
  ${sharedStyled}
  background: transparent;
  z-index: 5;
`

const Tutorial = props => {
  const {
    children,
    handleChangeTutorialHint,
    tutorialSequence,
    tutorialStep,
    tutorialTip,
  } = props

  return tutorialTip ? (
    <>
      <NextHandler data-cy="tutorial-overlay" onClick={() => handleChangeTutorialHint(tutorialStep + 1)} />
      <StyledBubble>
        {tutorialSequence.map(hint => hint.id === tutorialStep && hint.text)}
      </StyledBubble>
      <ChildrenWrapper {...props}>{children}</ChildrenWrapper>
      <Background />
      <Caption
        tutorialSequence={tutorialSequence}
        tutorialStep={tutorialStep}
        handleChangeTutorialHint={handleChangeTutorialHint}
      />
    </>
  ) : (
    <>{children}</>
  )
}

export default Tutorial
