import React, { useState } from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"

import H5 from "../../../../../shared/H5"
import SimpleContainer from "../../../../../shared/SimpleContainer"
import { useAppDispatch, useAppState } from "../../../../../../context/context"

const StyledSimpleContainer = styled(SimpleContainer)`
  display: flex;
`

const StyledInput = styled.input`
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  text-align: left;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
`

const ErrorMessage = styled.span`
  color: red;
  display: flex;
  padding-right: 5px;
`

const AddAspectButton = styled.span``

const AddAspect = ({ handleAddToSelected, aspectsUpdate }) => {
  const AppState = useAppState()
  const dispatch = useAppDispatch()
  const [input, setInput] = useState<string>("")
  const [regexError, setRegexError] = useState<boolean>(false)
  const [includeError, setIncludeError] = useState<boolean>(false)
  const [cookies, setCookie] = useCookies(["aspectsBase"])

  const addAspect = input => {
    if (!/^[a-zA-Z]+$/.test(input)) {
      setRegexError(true)
    }
    const exists = aspectsUpdate.find((aspect) => {
      return aspect.toLowerCase() === input.toLowerCase()
    }) || false

    if (exists) {
      setIncludeError(true)
    } else if (
      input.length > 0 &&
      /^[a-zA-Z]+$/.test(input)
    ) {
      if (!AppState.aspectsBase.includes(input)) {
        dispatch({ type: "ADD_ASPECT_TO_BASE", payload: input })
      }
      setCookie("aspectsBase", [...AppState.aspectsBase, input])
      setInput("")
      setRegexError(false)
      setIncludeError(false)
      handleAddToSelected(input)
    }
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      addAspect(input)
    }
  }

  return (
    <>
      <H5>Add Aspect</H5>
      <StyledSimpleContainer>
        <StyledInput
          data-cy="add-aspect-input-manager"
          placeholder="Enter new aspect..."
          onChange={e => {
            setInput(e.target.value)
            setRegexError(false)
          }}
          onKeyPress={handleKeyPress}
          value={input}
          maxLength={15}
        ></StyledInput>
        <ErrorMessage data-cy="add-aspect-error-manager">{regexError && "Invalid text input"}</ErrorMessage>
        <ErrorMessage data-cy="add-aspect-error-manager">{includeError && "Aspect already in base"}</ErrorMessage>
        <AddAspectButton
          data-cy="add-aspect-button-manager"
          onClick={() => {
            addAspect(input)
          }}
        >
          +
        </AddAspectButton>
      </StyledSimpleContainer>
    </>
  )
}

export default AddAspect
