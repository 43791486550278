import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useAppState } from "../../../../context/context"
import { backgroundStyles, hoverEffect } from "../../../../utils/sharedStyles"
import Info from "../../../shared/Info"

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 26px;
`

const StyledText = styled.p`
  text-align: justify;
`

const backgroundColor = scores => {
  if (scores[0] === Math.max.apply(Math, scores)) {
    return `rgba(213, 213, 213, ${scores[0]})`
  } else if (scores[1] === Math.max.apply(Math, scores)) {
    return `rgba(255, 74, 60, ${scores[1]})`
  } else {
    return `rgba(63, 224, 224, ${scores[2]})`
  }
}

const StyledSpan = styled.span<{ scores: number[] | boolean }>`
  ${backgroundStyles}
  ${hoverEffect}
  background: ${({ scores }) => (scores ? backgroundColor(scores) : "none")};
  cursor: pointer;
  box-decoration-break: clone;
  line-height: 1.55rem;

  ${({ theme }) => theme.media.tablet} {
    line-height: 1.5rem;
  }

  ${({ theme }) => theme.media.phone} {
    line-height: 1.3rem;
  }
`

const TextContainer = ({
  selectedAspects,
  handleModalVisibility,
  setSelectedSpansIndex,
}) => {
  const AppState = useAppState()
  const [sumExampleAverage, setSumExampleAverage] = useState<number[][]>([])

  //Displayed text scores generator
  useEffect(() => {
    const averageArray = selectedAspects.reduce((aggArr, arr, i) => {
      if (i === 0) {
        return arr.examples.map(a => a.scores.map(b => Number(b.toFixed(3))))
      } else {
        arr.examples.forEach((a, j) => {
          a.scores.forEach((b, k) => {
            aggArr[j][k] = Number(((aggArr[j][k] * i + b) / (i + 1)).toFixed(3))
          })
        })
      }
      return aggArr
    }, [])
    setSumExampleAverage(averageArray)
  }, [selectedAspects])

  return (
    <StyledContainer data-cy="output-text-home-page">
      <StyledText>
        {selectedAspects
          ? AppState.outputData.subTasks[0].examples.map((example, index) => (
              <StyledSpan
                onClick={() => {
                  handleModalVisibility()
                  setSelectedSpansIndex(index)
                }}
                key={index}
                scores={sumExampleAverage[index]}
              >
                {example.text}
              </StyledSpan>
            ))
          : AppState.outputData.subTasks[0].examples.map((example, index) => (
              <StyledSpan
                onClick={() => {
                  handleModalVisibility()
                  setSelectedSpansIndex(index)
                }}
                key={index}
                scores={false}
              >
                {example.text}
              </StyledSpan>
            ))}
      </StyledText>
      <Info>
        Colour of spans represent the average impact of selected aspects on
        spans. You can click on spans for details.
      </Info>
    </StyledContainer>
  )
}

export default TextContainer
