import React from "react"
import styled from "styled-components"

import { useAppState } from "../../../../../context/context"
import Chip from "../Chip"
import H5 from "../../../../shared/H5"

const StyledAspectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

const AspectsContainer = ({
  selectedAspects,
  handleSelectAspects,
  setSelectedAspectsIndex,
}) => {
  const AppState = useAppState()
  const { outputData } = AppState
  return (
    <>
      <H5>Choose aspect</H5>
      <StyledAspectsContainer data-cy="output-aspects-home-page">
        {outputData.subTasks &&
          outputData.subTasks.map((aspect, index) => (
            <Chip
              onClick={() => {
                handleSelectAspects(aspect)
                setSelectedAspectsIndex(index)
              }}
              selected={selectedAspects.includes(aspect)}
              sentiment={aspect.sentiment}
              key={index}
            >
              {aspect.aspect}
            </Chip>
          ))}
      </StyledAspectsContainer>
    </>
  )
}

export default AspectsContainer
