import React from "react"
import styled from "styled-components"

import { respTextFont } from "../../utils/sharedStyles"

const StyledWrapper = styled.div`
  width: 100%;
  padding: 0.3rem 1rem;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  opacity: 0.75;
  border: 2px solid rgba(156, 191, 238, 1);
  border-radius: 9px;
  text-align: left;
  cursor: pointer;
  user-select: none;

  ${respTextFont}
`

const SimpleContainer = props => {
  return <StyledWrapper {...props}>{props.children}</StyledWrapper>
}

export default SimpleContainer
