import React from "react"
import styled from "styled-components"
import ArrowIcon from "../../../utils/assets/ArrowIcon"

const Caption = props => {
  const { tutorialSequence, tutorialStep, handleChangeTutorialHint } = props

  const presentSequence = tutorialSequence?.find(hint => hint.id === tutorialStep)

  return (
    <StyledWrapper>
      {presentSequence && (
        <StyledDescription>{presentSequence.short}</StyledDescription>
      )}
      <PositionContainer>
        <StyledArrowIcon
          data-cy="previous-step-tutorial"
          onClick={() =>
            tutorialStep > 0 && handleChangeTutorialHint(tutorialStep - 1)
          }
          disabled={tutorialStep === 0}
          style={{ transform: "scaleX(-1)" }}
        />
        <DotsContainer data-cy="step-dots-tutorial">
          {tutorialSequence.map(hint => (
            <StyledDot 
              key={hint.id}
              active={hint.id <= tutorialStep}
              current={hint.id == tutorialStep}
              onClick={() => handleChangeTutorialHint(hint.id)}
            />
          ))}
        </DotsContainer>
        <StyledArrowIcon
          data-cy="next-step-tutorial"
          onClick={() => handleChangeTutorialHint(tutorialStep + 1)}
        />
      </PositionContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 20px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  user-select: none;
`

const StyledDescription = styled.div`
  text-align: center;
  padding: 6px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
`

const PositionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`

const StyledArrowIcon = styled(ArrowIcon)<{ disabled: boolean }>`
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const DotsContainer = styled.div`
  border: 2px solid white;
  border-radius: 20px;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  margin: auto;
`

const StyledDot = styled.div<{ active: boolean; current: boolean }>`
  width: 10px;
  height: 10px;
  border: 5px solid white;
  border-radius: 10px;
  margin: 12px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  cursor: ${({ current }) => (current ? "default" : "pointer")};
`

export default Caption
