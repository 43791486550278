import React from "react"
import styled from "styled-components"

const StyledSvg = styled.svg``

const ExpandIcon = props => {
  return (
    <StyledSvg width={15} height={10} viewBox="0 0 15 10" {...props}>
      <path
        data-name="Polygon 1"
        d="M7.5 0L15 10H0z"
        transform="rotate(180 7.5 5)"
        fill={props.color}
      />
    </StyledSvg>
  )
}

export default ExpandIcon
