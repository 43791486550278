import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { useCookies } from "react-cookie"

import Button from "../../shared/Button"
import TextInput from "./FirstPanelComponents/TextInput"
import AspectsUsed from "./FirstPanelComponents/AspectsUsed"
import SelectDocument from "./FirstPanelComponents/SelectDocument"
import ArrowIcon from "../../../utils/assets/ArrowIcon"
import { useAppState, useAppDispatch } from "../../../context/context"
import {
  buttonIconsStyle,
  respH1Font,
  respH2Font,
  respTextFont,
} from "../../../utils/sharedStyles"
import Tutorial from "../../tutorial/Tutorial"
import { selectErrorMessage } from "../../../utils/helpers"

const TitleContainer = styled.div``

const sharedStyles = css`
  margin: 10px 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
`

const StyledH1 = styled.h1`
  ${sharedStyles}
  ${respH1Font}
`

const StyledH2 = styled.h2`
  ${sharedStyles}
  ${respH2Font}
`

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const SelectDocumentContainer = styled.div`
  position: relative;
`

const SelectAspectContainer = styled.div`
  position: relative;
`

const InputContainer = styled.div`
  position: relative;
  height: 100%;
  margin: 10px 0;
`

const StyledTextInput = styled(TextInput)``

const TextLength = styled.span<{ textColor: boolean }>`
  ${respTextFont};

  color: ${props => props.textColor && "red"};
  position: absolute;
  right: 0.7rem;
  bottom: 0.7rem;
  border-radius: 9px;
  background: rgba(9, 8, 8, 0.8);
  padding: 5px;
`

const ButtonContainer = styled.div`
  position: relative;
`

const StyledIcon = styled(ArrowIcon)`
  ${buttonIconsStyle};
`

const FirstPanel = ({
  tutorialStep,
  handleChangeTutorialHint,
  tutorialSequence,
}) => {
  const AppState = useAppState()
  const { selectedDocument, dataLoading, indexTutorialVisibility } = AppState
  const { textInput, aspects } = selectedDocument
  const dispatch = useAppDispatch()
  const [cookies, setCookie] = useCookies(["aspectsBase"])
  const textLimit = 700

  useEffect(() => {
    cookies.aspectsBase &&
      dispatch({ type: "UPDATE_ASPECTS_BASE", payload: cookies.aspectsBase })
  }, [])

  const handleLoading = () => {
    dispatch({ type: "DATA_LOADING", payload: true })
    dispatch({ type: "SET_RESPONSE_ERROR", payload: false })
  }

  const handleSuccess = res => {
    dispatch({ type: "SET_OUTPUT", payload: res })
    dispatch({ type: "SET_RESPONSE_ERROR", payload: false })
    dispatch({ type: "DATA_LOADING", payload: false })
  }

  const handleError = (error: Error) => {
    dispatch({
      type: "SET_RESPONSE_ERROR_MSG",
      payload: selectErrorMessage(error.message),
    })
    dispatch({ type: "SET_RESPONSE_ERROR", payload: true })
    dispatch({ type: "DATA_LOADING", payload: false })
    console.log(error)
  }

  const handleDataFetch = () => {
    if (!dataLoading) {
      handleLoading()
      const backendEndpoint = process.env.NODE_ENV === 'production' ? "https://rolf-backend.scalac.io/api/v1/aspect" : "https://rolf-backend-dev.scalac.io/api/v1/aspect"
      fetch(backendEndpoint, {
        method: "post",
        body: JSON.stringify({ message: textInput, aspects: aspects }),
        headers: { "Content-type": "application/json" },
      })
        .then(res => {
          if (!res.ok) throw new Error(res.status.toString())
          return res.json()
        })
        .then(res => {
          if (!res.subTasks) throw new Error("no data")
          return handleSuccess(res)
        })
        .catch(error => handleError(error))
    }
  }

  return (
    <>
      <TitleContainer>
        <StyledH1>Understand Human Opinions in Language</StyledH1>
        <StyledH2>
          Rolf. is here to solve the world’s biggest problems by transforming
          your user-generated content into deep, actionable insights.
        </StyledH2>
      </TitleContainer>
      <InputWrapper data-cy="document-editor-home-page">
        <SelectDocumentContainer>
          <Tutorial
            handleChangeTutorialHint={handleChangeTutorialHint}
            tutorialStep={tutorialStep}
            tutorialSequence={tutorialSequence}
            tutorialTip={tutorialStep === 2 && indexTutorialVisibility}
          >
            <SelectDocument />
          </Tutorial>
        </SelectDocumentContainer>
        <SelectAspectContainer>
          <Tutorial
            handleChangeTutorialHint={handleChangeTutorialHint}
            tutorialStep={tutorialStep}
            tutorialSequence={tutorialSequence}
            tutorialTip={tutorialStep === 1 && indexTutorialVisibility}
          >
            <AspectsUsed />
          </Tutorial>
        </SelectAspectContainer>
        <InputContainer>
          <Tutorial
            handleChangeTutorialHint={handleChangeTutorialHint}
            tutorialStep={tutorialStep}
            tutorialSequence={tutorialSequence}
            tutorialTip={tutorialStep === 0 && indexTutorialVisibility}
          >
            <StyledTextInput />
          </Tutorial>
          {textInput.length > 0 && (
            <TextLength data-cy="input-length-home-page" textColor={textInput.length > textLimit}>
              {textInput.length}/{textLimit}
            </TextLength>
          )}
        </InputContainer>
      </InputWrapper>
      <ButtonContainer>
        <Tutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 3 && indexTutorialVisibility}
        >
          <Button
            disabled={
              dataLoading ||
              textInput === "" ||
              aspects.length === 0 ||
              textInput.length > textLimit
            }
            onClick={handleDataFetch}
          >
            Analyze Now
            <StyledIcon />
          </Button>
        </Tutorial>
      </ButtonContainer>
    </>
  )
}

export default FirstPanel
