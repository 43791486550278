import React from "react"
import styled from "styled-components"

const StyledSvg = styled.svg``

const DeleteIcon2 = props => {
  return (
    <StyledSvg
      width={15.06}
      height={15.06}
      viewBox="0 0 15.06 15.06"
      {...props}
    >
      <path d="M15.06 1.517L13.543 0 7.53 6.013 1.517 0 0 1.517 6.013 7.53 0 13.543l1.517 1.517L7.53 9.047l6.013 6.013 1.517-1.517L9.046 7.53z" />
    </StyledSvg>
  )
}

export default DeleteIcon2
