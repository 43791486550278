import React from "react"
import styled from "styled-components"

import MainLogo from "../../../../../../utils/assets/MainLogo"

const fontSize = 28

const StyledLogo = styled(MainLogo)`
  align-self: center;
  height: ${fontSize + 8}px;
  width: ${fontSize * 2.5}px;
`

const StyledH3 = styled.h3`
  display: flex;
  flex-grow: 1;
  color: ${({theme}) => theme.colors.primary};
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${fontSize}px;
  letter-spacing: 0px;
`

const ModalHeader = () => {
  return (
    <>
      <StyledLogo />
      <StyledH3>Inspect mode</StyledH3>
    </>
  )
}

export default ModalHeader
