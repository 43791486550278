import React from "react"
import styled from "styled-components"

import SimpleContainer from "../SimpleContainer"
import ExpandIcon from "../../../utils/assets/ExpandIcon"

const StyledSimpleContainer = styled(SimpleContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SelectFieldContainer = props => {
  return (
    <StyledSimpleContainer {...props}>{props.children}<ExpandIcon /></StyledSimpleContainer>
  )
}

export default SelectFieldContainer
