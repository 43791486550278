import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import { useAppState, useAppDispatch } from "../../../../../context/context"
import ModalContainer from "../../../../shared/ModalContainer"
import ModalHeader from "./ModalComponents/ModalHeader"
import AddAspect from "./ModalComponents/AddAspect"
import Aspects from "./ModalComponents/Aspects"
import Button from "../../../../shared/Button"
import Tutorial from "../../../../tutorial/Tutorial"
import { editModalSequence as tutorialSequence } from "../../../../tutorial/TutorialComponents/tutorialSequence"

const StyledModalContainer = styled(ModalContainer)`
  background: ${({ theme }) => theme.colors.primary};
`
const sharedContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 10px;
`

const HeaderContainer = styled.div`
  ${sharedContainerStyles}
`

const AddAspectContainer = styled.div`
  ${sharedContainerStyles}
  position: relative;
`

const AspectsContainer = styled.div`
  ${sharedContainerStyles}
  flex-grow: 1;
  position: relative;
`

const ButtonsContainer = styled.div`
  ${sharedContainerStyles}
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  margin-right: 13px;
`

const EditAspectsModal = props => {
  const AppState = useAppState()
  const { editTutorialVisibility } = AppState
  const dispatch = useAppDispatch()
  const [aspectsUpdate, setAspectUpdate] = useState<string[]>([])
  const [tutorialStep, setTutorialStep] = useState(0)

  useEffect(() => {
    setAspectUpdate(AppState.selectedDocument.aspects)
  }, [])

  const handleAddToSelected = aspect => {
    const newAspects = [...aspectsUpdate, aspect]
    setAspectUpdate(newAspects)
  }

  const handleRemoveFromSelected = aspect => {
    const newAspects = aspectsUpdate.filter(baseAspect => baseAspect !== aspect)
    setAspectUpdate(newAspects)
  }

  const handleDone = () => {
    props.handleModalVisibility()
    dispatch({ type: "SET_ASPECT_FROM_DOCUMENT", payload: aspectsUpdate })
  }

  const handleCancel = () => {
    props.handleModalVisibility()
  }

  useEffect(() => {
    setTutorialStep(0)
  }, [AppState.editTutorialVisibility])

  const handleChangeTutorialHint = (num) => {
    setTutorialStep(num)
    if (num === tutorialSequence.length) {
      dispatch({ type: "TOGGLE_EDIT_MODAL_TUTORIAL", payload: false })
    }
  }

  return (
    <StyledModalContainer
      handleModalVisibility={props.handleModalVisibility}
      handleShowModal={props.handleShowModal}
    >
      <HeaderContainer>
        <ModalHeader />
      </HeaderContainer>
      <AddAspectContainer>
        <Tutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 0 && editTutorialVisibility}
        >
          <AddAspect
            aspectsUpdate={aspectsUpdate}
            handleAddToSelected={handleAddToSelected}
          />
        </Tutorial>
      </AddAspectContainer>
      <AspectsContainer>
        <Tutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 1 && editTutorialVisibility}
        >
          <Aspects
            aspectsUpdate={aspectsUpdate}
            handleAddToSelected={handleAddToSelected}
            handleRemoveFromSelected={handleRemoveFromSelected}
          />
        </Tutorial>
      </AspectsContainer>
      <ButtonsContainer>
        <StyledButton onClick={handleDone}>Done</StyledButton>
        <StyledButton secondary onClick={handleCancel}>
          Cancel
        </StyledButton>
      </ButtonsContainer>
    </StyledModalContainer>
  )
}

export default EditAspectsModal
