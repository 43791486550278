import React, { useEffect, useState } from "react"
import styled from "styled-components"

import InteractiveContainer from "../../../../../shared/InteractiveContainer"
import { backgroundStyles } from "../../../../../../utils/sharedStyles"
import Info from "../../../../../shared/Info"

const StyledInteractiveContainer = styled(InteractiveContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.interactiveBorder}`};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const backgroundColor = props => {
  switch (props.sentiment) {
    case "positive":
      return `rgba(63, 224, 224, ${props.weights})`
    case "negative":
      return `rgba(255, 74, 60, ${props.weights})`
    default:
      return `rgba(213, 213, 213, ${props.weights})`
  }
}

type textTypes = {
  weights: number
  sentiment: string
}

const StyledSpan = styled.span<textTypes>`
  background: ${props => backgroundColor(props)};
  ${backgroundStyles};
`

const DecisionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 5px;
`

const Decision = styled.span<textTypes>`
  ${backgroundStyles};
  background: ${props => backgroundColor(props)};
  margin: 0.5rem;
  margin-bottom: 0;
`

const StyledInfo = styled(Info)`
  left: 5px;
  bottom: 5px;
`

const DecisionDetails = ({ selectedSpan, selectedPattern }) => {
  const [aspectsRep, setAspectRep] = useState([])

  //Transform selectedPattern to iterable format
  const transformSelectedPattern = () => {
    const transfPatternRepresentation = []
    const d = selectedPattern.tokens.length
    for (let i = 0; i < d; i++) {
      transfPatternRepresentation.push({
        id: i,
        weights: selectedPattern.weights[i].toFixed(2),
        tokens: selectedPattern.tokens[i],
      })
    }
    return setAspectRep(transfPatternRepresentation)
  }

  //Update transformSelectedPattern after change
  useEffect(() => {
    transformSelectedPattern()
  }, [selectedSpan, selectedPattern])

  return (
    <StyledInteractiveContainer>
      <TextContainer data-cy="decision-text-inspect-mode">
        {aspectsRep.map((pattern, index) => (
          <StyledSpan
            weights={pattern.weights}
            key={index}
            sentiment={selectedSpan.sentiment}
          >
            {pattern.tokens}
          </StyledSpan>
        ))}
      </TextContainer>
      <DecisionContainer >
        <Decision
          data-cy="decision-score-inspect-mode"
          weights={selectedPattern.impact}
          sentiment={selectedSpan.sentiment}
        >
          Decision:&nbsp;
          {selectedPattern.impact === 1 ? 1 : selectedPattern.impact.toFixed(2)}
        </Decision>
      </DecisionContainer>
      <StyledInfo>
        Intensity of verb's/sign's background define its influence in current pattern
      </StyledInfo>
    </StyledInteractiveContainer>
  )
}

export default DecisionDetails
