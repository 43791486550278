import React from "react"

const ArrowIcon = props => {
  return (
      <svg {...props} viewBox="0 0 9.9 13.899">
        <g transform="translate(-432.489 -740.99)" fill="#fff">
          <rect
            data-name="Rectangle 6"
            width={10}
            height={4}
            rx={2}
            transform="rotate(45 -676.795 895.97)"
          />
          <rect
            data-name="Rectangle 7"
            width={10}
            height={4}
            rx={2}
            transform="rotate(-45 1124.062 -146.03)"
          />
        </g>
      </svg>
  )
}

export default ArrowIcon
