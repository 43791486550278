import React from "react"
import styled from "styled-components"

import { useAppState, useAppDispatch } from "../../../../context/context"
import {
  respTextFont,
  scrollBarStyles,
  backgroundStyles,
} from "../../../../utils/sharedStyles"

const StyledTextarea = styled.textarea`
  ${respTextFont}
  ${scrollBarStyles}
  ${backgroundStyles}
  
  width: 100%;
  min-height: 200px;
  height: 100%;
  border: ${({ theme }) => `2px solid ${theme.colors.interactiveBorder}`};
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  resize: none;
  cursor: text;
  padding: 30px;
  transition: border 0.2s ease-out;

  ${({ theme }) => theme.media.tablet} {
    padding: 20px;
  }

  ${({ theme }) => theme.media.phone} {
    padding: 10px;
  }

  &:focus {
    border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    transition: border 0.3s ease-out;
  }
`

const TextInput = (props) => {
  const AppState = useAppState()
  const dispatch = useAppDispatch()

  return (
      <StyledTextarea
        data-cy="text-input-home-page"
        placeholder={"Enter your text"}
        onChange={e =>
          dispatch({
            type: "UPDATE_DOCUMENT_TEXT_INPUT",
            payload: e.target.value,
          })
        }
        value={AppState.selectedDocument.textInput}
        {...props}
      />
  )
}

export default TextInput
