import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"

import Layout from "../components/Layout"
import SEO from "../components/shared/Seo"

import { useAppDispatch, useAppState } from "../context/context"
import FirstPanel from "../components/index/FirstPanel/FirstPanel"
import SecPanel from "../components/index/SecPanel/SecPanel"
import PanelContainer from "../components/shared/PanelContainer"
import TutorialAgreement from "../components/index/TutorialAgreement"
import { indexSequence as tutorialSequence } from "../components/tutorial/TutorialComponents/tutorialSequence"

const AgreementWrapper = styled.div`
  position: relative;
`

const IndexPage = () => {
  const AppState = useAppState()
  const dispatch = useAppDispatch()
  const [tutorialStep, setTutorialStep] = useState(0)
  const [cookies, setCookie] = useCookies(["tutorial"])

  useEffect(() => {
    setTutorialStep(0)
  }, [AppState.indexTutorialVisibility])

  const handleChangeTutorialHint = (num) => {
    setTutorialStep(num)
    if (num === tutorialSequence.length) {
      dispatch({ type: "TOGGLE_INDEX_TUTORIAL", payload: false })
    }
  }

  const handleYes = () => {
    dispatch({ type: "HIDE_TUTORIAL_AGREEMENT", payload: false })
    dispatch({ type: "TOGGLE_TUTORIAL", payload: true })
    dispatch({ type: "TOGGLE_INDEX_TUTORIAL", payload: true })
    dispatch({ type: "TOGGLE_EDIT_MODAL_TUTORIAL", payload: true })
    dispatch({ type: "TOGGLE_INSPECT_MODAL_TUTORIAL", payload: true })
    setCookie("tutorial", true)
  }

  const handleNo = () => {
    dispatch({ type: "HIDE_TUTORIAL_AGREEMENT", payload: false })
    setCookie("tutorial", true)
  }

  return (
    <Layout>
      <SEO title="ROLF" />
      <AgreementWrapper>
        {!cookies.tutorial && AppState.tutorialAgreementVisibility && (
          <TutorialAgreement handleYes={handleYes} handleNo={handleNo} />
        )}
      </AgreementWrapper>
      <PanelContainer>
        <FirstPanel
          tutorialStep={tutorialStep}
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialSequence={tutorialSequence}
        />
      </PanelContainer>
      <PanelContainer>
        <SecPanel
          tutorialStep={tutorialStep}
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialSequence={tutorialSequence}
        />
      </PanelContainer>
    </Layout>
  )
}

export default IndexPage
