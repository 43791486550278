import React from "react"
import styled from "styled-components"

import { respTextFont } from "../../utils/sharedStyles"

const StyledH5 = styled.h5`
  ${respTextFont}

  text-align: left;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  color: ${({theme}) => theme.colors.primary};
  margin: 10px 0;
  cursor: default;
`

const H5 = props => <StyledH5 {...props}>{props.children}</StyledH5>

export default H5
