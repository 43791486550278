import React from "react"
import styled from "styled-components"

import { respTextFont } from "../../../utils/sharedStyles"

const SelectOptionWrapper = styled.li`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${(props: any) =>
    props.selected ? "blue" : props.theme.colors.background};
  padding: 10px 20px;
  text-align: left;
  cursor: pointer;
  list-style: none;
  margin: 0;

  ${respTextFont}
`

const SelectOption = props => {
  return <SelectOptionWrapper {...props}>{props.children}</SelectOptionWrapper>
}

export default SelectOption
