import React from "react"
import styled, { css } from "styled-components"

import InteractiveContainer from "../../../../../shared/InteractiveContainer"
import H5 from "../../../../../shared/H5"
import Measure from "../../../../../../utils/assets/Measure"
import ExpandIcon from "../../../../../../utils/assets/ExpandIcon"
import { hoverEffect } from "../../../../../../utils/sharedStyles"
import Magnify from "../../../../../../utils/assets/Magnify"
import Info from "../../../../../shared/Info"

const containerStyles = css`
  position: relative;
`
const arrowStyles = css`
  width: 14px;
  height: 8px;
  margin-bottom: 2px;
`
const StyledInteractiveContainer = styled(InteractiveContainer)`
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.interactiveBorder}`};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

const AxisContainer = styled.div`
  ${containerStyles}
  align-items: flex-end;
  display: flex;
  flex-direction: row;
`

const StyledArrowLeft = styled(ExpandIcon)`
  ${arrowStyles}
  transform: rotate(90deg);
`

const StyledUl = styled.ul`
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-right: 14px;
  flex-grow: 1;
`
const StyledLi = styled.li<{ impact: number; sentiment: string }>`
  ${hoverEffect}
  border: 1px solid white;
  position: absolute;
  border-radius: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: ${props => {
    switch (props.sentiment) {
      case "positive":
        return props.impact > 0 ? `rgba(63, 224, 224, ${props.impact})` : `none`
      case "negative":
        return props.impact > 0 ? `rgba(255, 74, 60, ${props.impact})` : `none`
      default:
        return props.impact > 0
          ? `rgba(213, 213, 213, ${props.impact})`
          : `none`
    }
  }};
  left: ${props => (Number(props.impact) + 1) * 50}%;

  &:hover {
    transform: scale(1.2);
  }
`

const Line = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 98%;
  margin-left: 5px;
  padding: 2.6px;
  z-index: -1;
`

const StyledArrowRight = styled(ExpandIcon)`
  ${arrowStyles}
  transform: rotate(-90deg);
`

const MeasureContainer = styled.div`
  ${containerStyles}
  margin: 0;
  width: 100%;
`

const StyledMeasure = styled(Measure)`
  position: absolute;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
`

const Scale = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 14px;
  width: 100%;
`

const NumberContainer = styled.span``

const scaleNumbers: number[] = [-1, -0.5, 0, 0.5, 1]

const StyledInfo = styled(Info)`
  left: 5px;
  bottom: 5px;
`

const Decision = ({ handleSelectPattern, selectedSpan }) => {
  return (
    <>
      <H5>Decision</H5>
      <StyledInteractiveContainer>
        <AxisContainer>
          <StyledArrowLeft color="white" />
          <StyledUl data-cy="decision-dots-inspect-mode">
            {selectedSpan.patterns
              .sort((a, b) => a.impact - b.impact)
              .map(pattern => (
                <StyledLi
                  key={pattern.impact}
                  onClick={() => handleSelectPattern(pattern)}
                  impact={pattern.impact.toFixed(2)}
                  sentiment={selectedSpan.sentiment}
                />
              ))}
          </StyledUl>
          <Line />
          <StyledArrowRight color="white" />
        </AxisContainer>
        <MeasureContainer>
          <StyledMeasure />
          <Scale>
            {scaleNumbers.map(number => (
              <NumberContainer key={number}>{number}</NumberContainer>
            ))}
          </Scale>
        </MeasureContainer>
        <StyledInfo>Click on dot to select pattern</StyledInfo>
      </StyledInteractiveContainer>
    </>
  )
}

export default Decision
