export const indexSequence = [
  {
    id: 0,
    short: "Write your own text",
    text: "You can write your own text to analyze.",
  },
  {
    id: 1,
    short: "Add aspects",
    text: "Add some aspects.",
  },
  {
    id: 2,
    short: "Select document",
    text: "Or just select prepared documents.",
  },
  {
    id: 3,
    short: "Click Analyze",
    text: "Click Analyze button to get result of chosen text."
  },
  {
    id: 4,
    short: "Choose aspect",
    text: "Choose one or multiple aspects to see impact on analyzed text",
  },
  {
    id: 5,
    short: "Analyze chosen text",
    text:
      "Colour and colour intensity represents chosen aspects impact on spans. Select span to see details of analyze",
  },
]

export const inspectModalSequence = [
  {
    id: 0,
    short: "Select aspect from dropdown",
    text:
      "Change aspects from a dropdown list, and spans in analyzed text with buttons",
  },
  {
    id: 1,
    short: "Analyze aspect impact on text",
    text: "Sentiment represents what impact aspect has on span below",
  },
  {
    id: 2,
    short: "Analyze aspects impact by word",
    text:
      "Visibility of Aspect definition represents the impact of words and punctuation marks on the selected span",
  },
  {
    id: 3,
    short: "Analyze patterns",
    text:
      "Dots represent patterns which model use to match correct sentiment impact",
  },
]

export const editModalSequence = [
  {
    id: 0,
    short: "Write custom aspects",
    text:
      "Write your own aspect. After approval, it will be automatically added to selected aspects.",
  },
  {
    id: 1,
    short: "Check aspects base",
    text:
      "Aspects on the left side will be added to analyze. Aspects on the right side are in aspects base. X on aspects will permanently remove aspects from the base.",
  },
]
