import React from "react"
import styled from "styled-components"

const SelectOptionsContainerWrapper = styled.div`
  position: relative;
`

const SelectOptions = styled.ul`
  position: absolute;
  width: 100%;
  padding: 0;
  background: ${({ theme }) => theme.colors.primary};
  opacity: 0.9;
  border: 2px solid rgba(156, 191, 238, 1);
  border-radius: 9px;
  user-select: none;
  z-index: 2;
`

const SelectOptionsContainer = props => {
  return (
    <SelectOptionsContainerWrapper>
      <SelectOptions data-cy="aspects-list-inspect-mode">{props.children}</SelectOptions>
    </SelectOptionsContainerWrapper>
  )
}

export default SelectOptionsContainer
