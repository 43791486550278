import React from "react"

const DeleteIcon = props => {
  return (
    <svg {...props} viewBox="0 0 20 20">
      <path d="M10 18a8 8 0 118-8 8.011 8.011 0 01-8 8m0-18a10 10 0 1010 10A9.991 9.991 0 0010 0m2.59 6L10 8.59 7.41 6 6 7.41 8.59 10 6 12.59 7.41 14 10 11.41 12.59 14 14 12.59 11.41 10 14 7.41z" />
    </svg>
  )
}

export default DeleteIcon
