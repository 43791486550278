import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"

import { useAppState, useAppDispatch } from "../../../../../context/context"
import { respTextFont } from "../../../../../utils/sharedStyles"

const StyledInput = styled.input`
  flex-grow: 1;
  border: none;
  height: 100%;
  outline: none;
`

const StyledAddAspect = styled.div<{ visible: boolean }>`
  display: flex;
  cursor: pointer;
  display: ${props => !props.visible && "none"};
`

const ErrorMessage = styled.span`
  color: red;
  display: flex;
  padding-right: 5px;
`

const StyledSeparator = styled.span<{ visible: boolean }>`
  ${respTextFont}
  display: flex;
  align-items: center;
  display: ${props => !props.visible && "none"};

  color: ${({ theme }) => theme.colors.secondary};
`

const AddAspectShortcut = props => {
  const AppState = useAppState()
  const { aspects } = AppState.selectedDocument
  const dispatch = useAppDispatch()
  const [input, setInput] = useState<string>("")
  const [regexError, setRegexError] = useState<boolean>(false)
  const [includeError, setIncludeError] = useState<boolean>(false)
  const [cookies, setCookie] = useCookies(["aspectsBase"])
  const [shortcutVisible, setShortcutVisible] = useState<boolean>(false)

  const handleInputChange = e => {
    setInput(e.target.value)
  }

  useEffect(() => {
    !input.length ? setShortcutVisible(false) : setShortcutVisible(true)
  }, [input])

  const addAspect = input => {
    if (!/^[a-zA-Z]+$/.test(input)) {
      setRegexError(true)
    }
    if (aspects.includes(input)) {
      setIncludeError(true)
    }

    if (
      input.length > 0 &&
      /^[a-zA-Z]+$/.test(input) &&
      !aspects.includes(input)
    ) {
      setCookie("aspectsBase", [...AppState.aspectsBase, input])
      setInput("")
      setRegexError(false)
      setIncludeError(false)
      dispatch({ type: "ADD_ASPECT_TO_BASE", payload: input })
      dispatch({ type: "ADD_ASPECT_TO_DOCUMENT", payload: [...aspects, input] })
    }
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      addAspect(input)
    }
  }

  useEffect(() => {
    setIncludeError(false)
    setRegexError(false)
  }, [input])

  return (
    <>
      <StyledInput
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        value={input}
      />
      {regexError && <ErrorMessage>Invalid text input</ErrorMessage>}
      {includeError && <ErrorMessage>Aspect already in base</ErrorMessage>}
      <StyledAddAspect
        visible={shortcutVisible}
        {...props}
        onClick={() => addAspect(input)}
      >
        Add Aspect
      </StyledAddAspect>
      <StyledSeparator visible={shortcutVisible}> | </StyledSeparator>
    </>
  )
}

export default AddAspectShortcut
