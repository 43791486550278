import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { useAppState, useAppDispatch } from "../../../../../context/context"
import ModalContainer from "../../../../shared/ModalContainer"
import ModalHeader from "./ModalComponents/ModalHeader"
import SelectAspect from "./ModalComponents/SelectAspect"
import Decision from "./ModalComponents/Decision"
import AspectDefinition from "./ModalComponents/AspectDefinition"
import SpanSentiment from "./ModalComponents/SpanSentiment"
import SelectSpan from "./ModalComponents/SelectSpan"
import DecisionDetails from "./ModalComponents/DecisionDetails"
import Tutorial from "../../../../tutorial/Tutorial"
import { inspectModalSequence as tutorialSequence } from "../../../../tutorial/TutorialComponents/tutorialSequence"

const sharedContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 6px 10px;
`

const HeaderContainer = styled.div`
  ${sharedContainerStyles}
  flex-direction: row;
`

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

const StyledTutorial = styled(Tutorial)`
  display: flex;
  flex-direction: row;
`

const SelectAspectContainer = styled.div`
  ${sharedContainerStyles}
  width: 50%;
`

const SelectSpanContainer = styled.div`
  ${sharedContainerStyles}
  width: 50%;
`

const AspectDefinitionContainer = styled.div`
  ${sharedContainerStyles}
  position: relative;
`

const DecisionWrapper = styled.div`
  position: relative;
`

const DecisionContainer = styled.div`
  ${sharedContainerStyles}
  margin-bottom: 0;
`

const DecisionDetailsContainer = styled.div`
  ${sharedContainerStyles}
  margin-top: 0;
  position: relative;
  flex-grow: 1;
`

const InspectModeModal = ({
  handleModalVisibility,
  selectedAspectsIndex,
  selectedSpansIndex,
}) => {
  const AppState = useAppState()
  const { inspectTutorialVisibility } = AppState
  const { subTasks } = AppState.outputData
  const dispatch = useAppDispatch()
  const [aspectIndex, setAspectsIndex] = useState<number>(selectedAspectsIndex)
  const [selectedAspect, setSelectedAspect] = useState(
    subTasks[selectedAspectsIndex]
  )
  const { examples } = selectedAspect
  const [spansIndex, setSpansIndex] = useState<number>(selectedSpansIndex)
  const [selectedSpan, setSelectedSpan] = useState(examples[spansIndex])
  const [selectedPattern, setSelectedPattern] = useState(
    selectedSpan.patterns[selectedSpan.patterns.length - 1]
  )
  const [tutorialStep, setTutorialStep] = useState(0)

  //Adjust Aspect change
  useEffect(() => {
    setSelectedSpan(examples[spansIndex])
  }, [selectedAspect])

  //Handle Select
  const handleAspectSelect = index => {
    setSelectedAspect(subTasks[index])
    setAspectsIndex(index)
  }

  //Handle Span change
  const handleSpanChangeBack = () => {
    if (spansIndex > 0) {
      const newIndex = spansIndex - 1
      setSelectedSpan(examples[newIndex])
      setSpansIndex(newIndex)
      setSelectedPattern(
        examples[newIndex].patterns[examples[newIndex].patterns.length - 1]
      )
    }
  }
  const handleSpanChangeForw = () => {
    if (spansIndex < examples.length - 1) {
      const newIndex = spansIndex + 1
      setSelectedSpan(examples[newIndex])
      setSpansIndex(newIndex)
      setSelectedPattern(
        examples[newIndex].patterns[examples[newIndex].patterns.length - 1]
      )
    }
  }

  useEffect(() => {
    setSelectedPattern(
      examples[spansIndex].patterns[examples[spansIndex].patterns.length - 1]
    )
  }, [selectedSpan])

  const handleSelectPattern = pattern => {
    setSelectedPattern(pattern)
  }

  useEffect(() => {
    setTutorialStep(0)
  }, [AppState.inspectTutorialVisibility])

  const handleChangeTutorialHint = (num) => {
    setTutorialStep(num)
    if (num === tutorialSequence.length) {
      dispatch({ type: "TOGGLE_INSPECT_MODAL_TUTORIAL", payload: false })
    }
  }

  return (
    <ModalContainer handleModalVisibility={handleModalVisibility}>
      <HeaderContainer>
        <ModalHeader />
      </HeaderContainer>
      <SelectWrapper>
        <StyledTutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 0 && inspectTutorialVisibility}
        >
          <SelectAspectContainer>
            <SelectAspect
              aspectIndex={aspectIndex}
              handleAspectSelect={handleAspectSelect}
            />
          </SelectAspectContainer>
          <SelectSpanContainer>
            <SelectSpan
              handleSpanChangeBack={handleSpanChangeBack}
              handleSpanChangeForw={handleSpanChangeForw}
              examples={examples}
              spansIndex={spansIndex}
            />
          </SelectSpanContainer>
        </StyledTutorial>
      </SelectWrapper>
      <AspectDefinitionContainer>
        <Tutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 1 && inspectTutorialVisibility}
        >
          <SpanSentiment selectedSpan={selectedSpan} />
        </Tutorial>
      </AspectDefinitionContainer>
      <AspectDefinitionContainer>
        <Tutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 2 && inspectTutorialVisibility}
        >
          <AspectDefinition selectedSpan={selectedSpan} />
        </Tutorial>
      </AspectDefinitionContainer>
      <DecisionWrapper>
        <Tutorial
          handleChangeTutorialHint={handleChangeTutorialHint}
          tutorialStep={tutorialStep}
          tutorialSequence={tutorialSequence}
          tutorialTip={tutorialStep === 3 && inspectTutorialVisibility}
        >
          <DecisionContainer>
            <Decision
              handleSelectPattern={handleSelectPattern}
              selectedSpan={selectedSpan}
            />
          </DecisionContainer>
          <DecisionDetailsContainer>
            <DecisionDetails
              selectedSpan={selectedSpan}
              selectedPattern={selectedPattern}
            />
          </DecisionDetailsContainer>
        </Tutorial>
      </DecisionWrapper>
    </ModalContainer>
  )
}

export default InspectModeModal
