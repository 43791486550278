import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Bubble from "./Bubble"

const InfoWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 10px;
  user-select: none;
`

const Hint = styled(Bubble)<{ visible: boolean }>`
  width: 300px;
  position: absolute;
  bottom: 2rem;
  left: -20px;
  display: ${props => (props.visible ? "default" : "none")};
`

const InfoIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  border-radius: 50%;
`

const InfoIcon = styled.span`
  font-size: 0.6rem;
`

const Info = props => {
  const [visible, setVisible] = useState(false)

  const handleShow = () => {
    setVisible(true)
  }

  const handleHide = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      window.addEventListener("click", handleHide)
      return () => {
        window.removeEventListener("click", handleHide)
      }
    }
  }, [visible])

  return (
    <InfoWrapper onClick={handleShow} {...props}>
      <Hint visible={visible}>{props.children}</Hint>
      <InfoIconWrapper>
        <InfoIcon>i</InfoIcon>
      </InfoIconWrapper>
    </InfoWrapper>
  )
}

export default Info
