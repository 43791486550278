import React from "react"

const CloseIcon = props => {
  return (
    <svg {...props} viewBox="0 0 120 69">
      <defs>
        <clipPath id="a">
          <path opacity={0.765} fill="#fff" d="M0 0H120V69H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M59.539-.19a33.888 33.888 0 11-33.888 33.888A33.858 33.858 0 0159.539-.19m12.166 16.944L59.539 28.92 47.373 16.754l-4.778 4.778 12.166 12.166-12.166 12.166 4.778 4.778 12.166-12.166 12.166 12.166 4.778-4.778-12.166-12.166 12.166-12.166z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default CloseIcon
