import React, { useState, useEffect } from "react"
import styled from "styled-components"

import InteractiveContainer from "../../../../../shared/InteractiveContainer"
import H5 from "../../../../../shared/H5"
import { backgroundStyles } from "../../../../../../utils/sharedStyles"
import Info from "../../../../../shared/Info"

const StyledInteractiveContainer = styled(InteractiveContainer)`
  width: 100%;
  min-height: 100px;
  display: flex;
`

const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
`

const StyledSpan = styled.span<{ comeFrom: number; sentiment: string }>`
  ${backgroundStyles};
  color: ${props => `rgba(233, 233, 233, ${props.comeFrom})`};
`

const StyledInfo = styled(Info)`
  left: 7px;
  bottom: 7px;
`

const AspectDefinition = ({ selectedSpan }) => {
  const [aspectsRep, setAspectRep] = useState([])

  const transformSelectedSpan = () => {
    const transfAspectRepresentation = []
    const d = selectedSpan.aspectRepresentation.tokens.length
    for (let i = 0; i < d; i++) {
      transfAspectRepresentation.push({
        id: i,
        comeFrom: Number(
          selectedSpan.aspectRepresentation.comeFrom[i].toFixed(2)
        ),
        lookAt: Number(selectedSpan.aspectRepresentation.lookAt[i].toFixed(2)),
        tokens: selectedSpan.aspectRepresentation.tokens[i],
      })
    }
    return setAspectRep(transfAspectRepresentation)
  }

  useEffect(() => {
    transformSelectedSpan()
  }, [selectedSpan])

  return (
    <>
      <H5>Aspect definitions</H5>
      <StyledInteractiveContainer>
        <TextContainer data-cy="aspect-definition-text-inspect-mode">
          {aspectsRep.map(aspect => (
            <StyledSpan
              sentiment={selectedSpan.sentiment}
              comeFrom={aspect.comeFrom}
              key={aspect.id}
            >
              {aspect.tokens}
            </StyledSpan>
          ))}
        </TextContainer>
        <StyledInfo>
          The opacity of verbs represent its impact in span's analyze
        </StyledInfo>
      </StyledInteractiveContainer>
    </>
  )
}

export default AspectDefinition
