import React from "react"
import styled, { keyframes } from "styled-components"
import { theme } from "../../utils/theme"

const LoaderWrapper = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: ${({ theme }) => theme.fonts.primary};
`

const LoaderBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`

const StyledLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`

// Loader Animation
const rotation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(-360deg);
}
`
const scale50 = keyframes`
0%, 100% {
  transform: scale(0);
}
50% {
  transform: scale(1);
}
`

const StyledLoader = styled.div`
  width: 80px;
  height: 80px;
  display: block;
  margin: 15px auto 30px;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: ${rotation} 2s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    background-color: #fff;
    border-radius: 50%;
    animation: ${scale50} 2s infinite ease-in-out;
    top: auto;
    bottom: 0;
    background-color: #ff3d00;
    animation-delay: 0.5s;
  }
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    background-color: #fff;
    border-radius: 50%;
    animation: ${scale50} 2s infinite ease-in-out;
  }
`

const Loader = props => {
  return (
      <LoaderWrapper>
        <LoaderBody>
          <StyledLoaderWrapper>
            <StyledLoader></StyledLoader>
            <h2>Loading ...</h2>
          </StyledLoaderWrapper>
        </LoaderBody>
      </LoaderWrapper>
  )
}

export default Loader
