import React, { useEffect } from "react"
import styled from "styled-components"

import CloseIcon from "../../utils/assets/CloseIcon"

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 30px;
  width: 600px;
  min-height: 90%;

  ${({ theme }) => theme.media.tablet} {
    width: 500px;
  }

  ${({ theme }) => theme.media.phone} {
    max-width: 400px;
    width: 95vw;
  }
`

const CloseButton = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const StyledImg = styled(CloseIcon)`
  position: absolute;
  height: 60px;
  top: -15px;
  right: -35px;
  cursor: pointer;

  ${({ theme }) => theme.media.tablet} {
    height: 50px;
  }

  ${({ theme }) => theme.media.phone} {
    height: 40px;
    top: -17px;
    right: -33px;
  }
`

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.colors.background};
  border: 2px solid rgba(156, 191, 238, 1);
  border-radius: 9px;
  z-index: 1;
`

const ModalContainer = props => {
  useEffect(() => {
    document.addEventListener("keydown", handleEscPress, false)
    return () => {
      document.removeEventListener("keydown", handleEscPress, false)
    }
  }, [])

  const handleEscPress = e => {
    if (e.key === "Escape") {
      props.handleModalVisibility()
    }
  }

  return (
    <ModalWrapper>
      <ModalBody>
        <StyledModal>
          <CloseButton>
            <StyledImg
              data-cy="close-window-shared"
              onClick={() => {
                props.handleModalVisibility()
              }}
            />
          </CloseButton>
          {props.children}
        </StyledModal>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalContainer
