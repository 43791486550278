import React from "react"

import styled from "styled-components"
import { backgroundStyles, hoverEffect } from "../../../../utils/sharedStyles"

const sentimentStyle = (sentiment, selected) => {
  switch (sentiment) {
    case "negative":
      return ({ selected }) =>
        selected ? `rgba(255, 74, 60, 1)` : `rgba(255, 74, 60, 0.6)`
    case "neutral":
      return ({ selected }) =>
        selected ? `rgba(213, 213, 213, 1)` : `rgba(213, 213, 213, 0.6)`
    case "positive":
      return ({ selected }) =>
        selected ? `rgba(63, 224, 224, 1)` : `rgba(63, 224, 224, 0.6)`
    default:
      return ({ selected }) =>
        selected ? `rgba(233, 213, 213, 1)` : `rgba(213, 213, 213, 0.6)`
  }
}

type StyledChipTypes = {
  onClick: MouseEvent
  children: string
  selected: boolean
  sentiment: string
}

const StyledChip = styled.div<StyledChipTypes>`
  ${backgroundStyles}
  ${hoverEffect}
  display: flex;
  flex-direction: row;
  user-select: none;
  margin: 5px;
  height: fit-content;
  text-shadow: none;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.background};
  background: ${({ sentiment, selected }) =>
    sentimentStyle(sentiment, selected)};
  text-shadow: ${({ selected }) =>
    selected && "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"};
  cursor: pointer;
`

const Chip = ({ onClick, children, selected, sentiment }) => (
  <StyledChip onClick={onClick} selected={selected} sentiment={sentiment}>
    {children}
  </StyledChip>
)

export default Chip
