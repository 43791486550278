import React from "react"

const Measure = props => {
  return (
    <svg width="743" height="9" viewBox="0 0 743 9" {...props} >
      <defs>
        <clipPath id="clipPath">
          <rect width="743" height="9" fill="none" />
        </clipPath>
      </defs>
      <g
        id="Repeat_Grid_12"
        data-name="Repeat Grid 12"
        clipPath="url(#clipPath)"
      >
        <g transform="translate(-631 -418.5)">
          <line
            id="Line_31"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-622 -418.5)">
          <line
            id="Line_31-2"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-613 -418.5)">
          <line
            id="Line_31-3"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-604 -418.5)">
          <line
            id="Line_31-4"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-595 -418.5)">
          <line
            id="Line_31-5"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-586 -418.5)">
          <line
            id="Line_31-6"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-577 -418.5)">
          <line
            id="Line_31-7"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-568 -418.5)">
          <line
            id="Line_31-8"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-559 -418.5)">
          <line
            id="Line_31-9"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-550 -418.5)">
          <line
            id="Line_31-10"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-541 -418.5)">
          <line
            id="Line_31-11"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-532 -418.5)">
          <line
            id="Line_31-12"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-523 -418.5)">
          <line
            id="Line_31-13"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-514 -418.5)">
          <line
            id="Line_31-14"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-505 -418.5)">
          <line
            id="Line_31-15"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-496 -418.5)">
          <line
            id="Line_31-16"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-487 -418.5)">
          <line
            id="Line_31-17"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-478 -418.5)">
          <line
            id="Line_31-18"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-469 -418.5)">
          <line
            id="Line_31-19"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-460 -418.5)">
          <line
            id="Line_31-20"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-451 -418.5)">
          <line
            id="Line_31-21"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-442 -418.5)">
          <line
            id="Line_31-22"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-433 -418.5)">
          <line
            id="Line_31-23"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-424 -418.5)">
          <line
            id="Line_31-24"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-415 -418.5)">
          <line
            id="Line_31-25"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-406 -418.5)">
          <line
            id="Line_31-26"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-397 -418.5)">
          <line
            id="Line_31-27"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-388 -418.5)">
          <line
            id="Line_31-28"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-379 -418.5)">
          <line
            id="Line_31-29"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-370 -418.5)">
          <line
            id="Line_31-30"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-361 -418.5)">
          <line
            id="Line_31-31"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-352 -418.5)">
          <line
            id="Line_31-32"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-343 -418.5)">
          <line
            id="Line_31-33"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-334 -418.5)">
          <line
            id="Line_31-34"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-325 -418.5)">
          <line
            id="Line_31-35"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-316 -418.5)">
          <line
            id="Line_31-36"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-307 -418.5)">
          <line
            id="Line_31-37"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-298 -418.5)">
          <line
            id="Line_31-38"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-289 -418.5)">
          <line
            id="Line_31-39"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-280 -418.5)">
          <line
            id="Line_31-40"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-271 -418.5)">
          <line
            id="Line_31-41"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-262 -418.5)">
          <line
            id="Line_31-42"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-253 -418.5)">
          <line
            id="Line_31-43"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-244 -418.5)">
          <line
            id="Line_31-44"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-235 -418.5)">
          <line
            id="Line_31-45"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-226 -418.5)">
          <line
            id="Line_31-46"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-217 -418.5)">
          <line
            id="Line_31-47"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-208 -418.5)">
          <line
            id="Line_31-48"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-199 -418.5)">
          <line
            id="Line_31-49"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-190 -418.5)">
          <line
            id="Line_31-50"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-181 -418.5)">
          <line
            id="Line_31-51"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-172 -418.5)">
          <line
            id="Line_31-52"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-163 -418.5)">
          <line
            id="Line_31-53"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-154 -418.5)">
          <line
            id="Line_31-54"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-145 -418.5)">
          <line
            id="Line_31-55"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-136 -418.5)">
          <line
            id="Line_31-56"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-127 -418.5)">
          <line
            id="Line_31-57"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-118 -418.5)">
          <line
            id="Line_31-58"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-109 -418.5)">
          <line
            id="Line_31-59"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-100 -418.5)">
          <line
            id="Line_31-60"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-91 -418.5)">
          <line
            id="Line_31-61"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-82 -418.5)">
          <line
            id="Line_31-62"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-73 -418.5)">
          <line
            id="Line_31-63"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-64 -418.5)">
          <line
            id="Line_31-64"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-55 -418.5)">
          <line
            id="Line_31-65"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-46 -418.5)">
          <line
            id="Line_31-66"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-37 -418.5)">
          <line
            id="Line_31-67"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-28 -418.5)">
          <line
            id="Line_31-68"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-19 -418.5)">
          <line
            id="Line_31-69"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-10 -418.5)">
          <line
            id="Line_31-70"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-1 -418.5)">
          <line
            id="Line_31-71"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(8 -418.5)">
          <line
            id="Line_31-72"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(17 -418.5)">
          <line
            id="Line_31-73"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(26 -418.5)">
          <line
            id="Line_31-74"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(35 -418.5)">
          <line
            id="Line_31-75"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(44 -418.5)">
          <line
            id="Line_31-76"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(53 -418.5)">
          <line
            id="Line_31-77"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(62 -418.5)">
          <line
            id="Line_31-78"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(71 -418.5)">
          <line
            id="Line_31-79"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(80 -418.5)">
          <line
            id="Line_31-80"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(89 -418.5)">
          <line
            id="Line_31-81"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(98 -418.5)">
          <line
            id="Line_31-82"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(107 -418.5)">
          <line
            id="Line_31-83"
            data-name="Line 31"
            y2="23"
            transform="translate(631.5 418.5)"
            fill="none"
            stroke="#6f6f6f"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Measure
